import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SupplierVoucherFile } from 'src/app/models/FromApiModels';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-voucher-liste-modal',
  templateUrl: './voucher-liste-modal.component.html',
  styleUrls: ['./voucher-liste-modal.component.scss'],
})
export class VoucherListeModalComponent {
  @Input() data: SupplierVoucherFile[];

  constructor(
    public modalController: ModalController
  ) { }

  onOpenlink(link: string) {
    if (typeof link === 'string') {
      window.open(environment.BASE_URL + link, '_blank');
    }
  }

}
