/* eslint-disable max-len */
import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ContactService } from 'src/app/services/contact.service';
import { DataService } from 'src/app/services/data.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DateSelectorPopoverComponent } from '../date-selector-popover/date-selector-popover.component';

@Component({
  selector: 'app-reservation-point-interet-modal',
  templateUrl: './reservation-point-interet-modal.component.html',
  styleUrls: ['./reservation-point-interet-modal.component.scss'],
})
export class ReservationPointInteretModalComponent implements OnInit, OnDestroy {
  @Input() props: {
    image: string;
    title: string;
    text: string;
    rating: number | null;
    itineraireButton: boolean;
    reservationButton: boolean;
    address: string | null;
    phone: string | null;
    category: string | null;
    categoryAsString: string | null;
    priceAmount: number | null;
    priceCurrency: 'EUR';
  };
  @Input() item: any;
  countryCode = this.contactService.countryCode;
  numero = this.contactService.numero;
  odasieMail = this.contactService.odasieMail;

  whatsappUrl: string;
  emailUrl: string;

  // today = `${new Date().toISOString().slice(0,10)}`; // old, champ classique

  today = `${new Date().toISOString().slice(0,11)}00:00:00.000Z`;
  maxDate = new Date(this.today);
  max = ((date: Date) => {
    date.setFullYear(date.getFullYear() + 1);
    date.setMonth(11);
    date.setDate(31);
    return `${date.toISOString().slice(0,11)}00:00:00.000Z`;
  })(this.maxDate);

  dayString = this.today.slice(8, 10);
  monthString = this.today.slice(5, 7);
  yearString = this.today.slice(0, 4);

  form: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private contactService: ContactService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private utilsService: UtilsService
  ) { }

   ngOnInit() {
    this.form = this.formBuilder.group({
      date: [this.today, [Validators.required, this.dateMinimum(this.today)]],
      nbPersonnes: [1, [Validators.required, Validators.min(1)]]
    });

    this.subscriptions.push(this.form.controls.date.valueChanges.pipe(startWith(this.form.value.date)).subscribe(val => {
      if (typeof val === 'string') {
        const dayString = val.slice(8,10);
        const monthString = val.slice(5,7);
        const yearString = val.slice(0,4);
        const dmCheckRegex = /^\d{2}$/;
        const yCheckRegex = /^\d{4}$/;
        if (dmCheckRegex.test(dayString) && dmCheckRegex.test(monthString) && yCheckRegex.test(yearString)) {
          this.dayString = dayString;
          this.monthString = monthString;
          this.yearString = yearString;
        }
      } else {
        this.dayString = '';
        this.monthString = '';
        this.yearString = '';
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  closeModal() {
    this.modalController.dismiss();
  }

  to(where: 'Whatsapp' | 'Email') {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      switch (where) {
        case 'Whatsapp':
          this.toWhatsapp();
          break;
        case 'Email':
          this.toEmail();
          break;
      }
    }
  }

  toWhatsapp() {
    this.dataService.getUser().pipe(
      map(userInfo => userInfo && userInfo.fullname && userInfo.fullname.length > 0 && `,%0D%0A%0D%0A${userInfo.fullname.replace(' ', `%20`)}` || '')
    ).toPromise().then(fullname => {
      const message = this.message(fullname);
      this.whatsappUrl = `https://api.whatsapp.com/send?phone=${this.countryCode}${this.numero}&text=${message}`;
      window.open(this.whatsappUrl, '_blank');
    });
  }

  toEmail() {
    this.dataService.getUser().pipe(
      map(userInfo => userInfo && userInfo.fullname && userInfo.fullname.length > 0 && `,%0D%0A%0D%0A${userInfo.fullname.replace(' ', `%20`)}` || '')
    ).toPromise().then(fullname => {
      const message = this.message(fullname);
      this.emailUrl = `mailto:${this.odasieMail}.fr?Subject=Réservation&body=${message}`;
      window.open(this.emailUrl, '_blank');
    });
  }

  message(fullname: string) {
    const datepipe = new DatePipe('fr-FR');
    const { date, nbPersonnes } = this.form.value;
    const categoryLabel = typeof this.props?.categoryAsString === 'string' ? this.props?.categoryAsString.toLowerCase() : '';
    const dateToInject = datepipe.transform(date, 'dd/MM/yyyy');
    const nbPersonnesToInject = parseInt(nbPersonnes, 10);
    const label = typeof this.item?.label === 'string' ? this.item.label.toLowerCase() : '';
    const personneStr = nbPersonnesToInject > 1 ? 'personnes' : 'personne';
    const message = (categoryLabel.length > 0 && label.length > 0)
    ? `Bonjour,%0D%0A%0D%0Aje%20souhaiterais%20réserver%20le%20service%20suivant%20:%20${categoryLabel}%20%C2%AB%20${label}%20%C2%BB%20à%20la%20date%20du%20${dateToInject}%20pour%20${nbPersonnesToInject}%20${personneStr}.%0D%0A%0D%0AMerci${fullname}`
    : `Bonjour,%0D%0A%0D%0Aje%20souhaiterais%20réserver%20un%20service%20à%20la%20date%20du%20${dateToInject}%20pour%20${nbPersonnesToInject}%20${personneStr}.%0D%0A%0D%0AMerci${fullname}`;
    return message;
  }

  dateMinimum(minDate: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }
      const dateAsDate = new Date(control.value);
      const validationDate = new Date(minDate);
      return dateAsDate.getTime() >= validationDate.getTime()
      ? null
      : {
        dateMinimum: {
          'date-minimum': `Aujourd'hui`,
          actual: control.value
        }
      };
    };
  }

  async openDateSelectorPopover($event) {
      this.utilsService.presentModale(this.utilsService.alertLikeModal(
        DateSelectorPopoverComponent,
        {
          fc: this.form.controls.date,
          min: this.today,
          max: this.max,
        },
        '',
        _ => { this.form.controls.date.markAsDirty(); this.form.controls.date.markAsTouched(); }
      )
    );
  }

}
