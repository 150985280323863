import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Customtour } from 'src/app/models/FromApiModels';

@Component({
  selector: 'app-reservation-modal',
  templateUrl: './reservation-modal.component.html',
  styleUrls: ['./reservation-modal.component.scss'],
})
export class ReservationModalComponent implements OnInit {
  @Input() customtour: Customtour;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
