import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum'
})
export class SumPipe implements PipeTransform {

  transform(...args: string[]): number {
    return args.reduce((acc, cur) => acc + Number(cur), 0);
  }

}
