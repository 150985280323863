import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  countryCode = '66';
  numero = '809392329'; // TODO-pf vérifier avant de valider
  odasieMail = `voyage@odasie.fr`; // TODO-pf vérifier avant de valider

  constructor() { }
}
