import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-date-selector-popover',
  templateUrl: './date-selector-popover.component.html',
  styleUrls: ['./date-selector-popover.component.scss'],
})
export class DateSelectorPopoverComponent {
  @Input() fc: FormControl;
  @Input() min: string;
  @Input() max: string;

  constructor(
    private modalController: ModalController
  ) { }

  closeModal() {
    this.modalController.dismiss();
  }

}
