import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FullUri } from 'src/app/models/Models';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private dataService: DataService,
    private httpClient: HttpClient,
  ) { }

  // GET

  apiGetT<T>(fullUri: FullUri, _headers = [], params = undefined) {
    const op$ = (headers: [string, string][]) => this.apiGet<T>(fullUri, headers, params);

    return this.dataService.getToken().pipe(
      map(token => [['Authorization', `Bearer ${token}`]]),
      concatMap(op$)
    );
  }

  apiGet<T>(fullUri: FullUri, _headers = [], params = undefined) {
    const uri = this.uriFrom(fullUri);
    const headers = this.makeHeader(_headers);
    return this.httpClient.get<T>(uri, { headers, params });
  }

  // POST

  apiPostT<T>(fullUri: FullUri, body, _headers = []) {
    const op$ = (headers: [string, string][]) => this.apiPost<T>(fullUri, body, headers);

    return this.dataService.getToken().pipe(
      map(token => [['Authorization', `Bearer ${token}`]]),
      concatMap(op$)
    );
  }

  apiPost<T>(fullUri: FullUri, body, _headers = []) {
    const uri = this.uriFrom(fullUri);
    const headers = this.makeHeader(_headers);
    return this.httpClient.post<T>(uri, body, {headers});
  }

  // GETBLOB

  apiGetBlobT(fullUri: FullUri): Observable<Blob> {
    const op$ = (headers: [string, string][]) => this.apiGetBlob(fullUri, headers);

    return this.dataService.getToken().pipe(
      map(token => [['Authorization', `Bearer ${token}`]]),
      concatMap(op$)
    );
  }

  apiGetBlob(fullUri: FullUri, _headers = []): Observable<Blob> {
    const uri = this.uriFrom(fullUri);
    const headers = this.makeHeader(_headers);

    return this.httpClient.get(uri, { responseType: 'blob', headers});
  }

  makeHeader(_headers: [string, string][]): HttpHeaders {
    return (_headers || []).reduce((acc, cur) => acc.append(cur[0], cur[1]), new HttpHeaders());
  }

  uriFrom(fullUri: FullUri) {
    return (fullUri.uriHead || environment.API_URL ) + fullUri.uriTail;
  }

}
