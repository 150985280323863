import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Day, Hotel } from 'src/app/models/FromApiModels';

@Component({
  selector: 'app-hebergement-modal',
  templateUrl: './hebergement-modal.component.html',
  styleUrls: ['./hebergement-modal.component.scss'],
})
export class HebergementModalComponent implements OnInit {
  @Input() day: Day;
  @Input() hotels: Hotel[];

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
