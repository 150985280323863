import { Injectable } from '@angular/core';
import { LoadingController, LoadingOptions, MenuController, ModalController, ToastController } from '@ionic/angular';
import { defer, from, Observable } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private loadingController: LoadingController,
    private menuController: MenuController,
    private modalController: ModalController,
    private toastController: ToastController
  ) { }

  encapsulateWithinLoader<T>(obs: Observable<T>, options: LoadingOptions): Observable<T> {
    return from(this.loadingController.create(options)).pipe(
      concatMap(loader => from(loader.present()).pipe(
        concatMap(_ => obs.pipe(
          tap(
            __ => loader.dismiss(),
            __ => loader.dismiss()
          )
        ))
      ))
    );
  }

  flashToast(message: string, duration = 2000, color: 'danger' | 'success' = undefined, position: 'bottom' | 'top' | 'middle' = 'bottom') {
    return new Promise((resolve) => {
      this.toastController.create({
        message,
        duration,
        color,
        position
      })
      .then(toast => {
        toast.present();
        return toast.onDidDismiss();
      })
      .then(_ => {
        resolve(_);
      });
    });
  }

  toggleMenu(bool: boolean) {
    this.menuController.isEnabled().then(isEnabled => {
      if (isEnabled === !bool) {
        this.menuController.enable(bool);
      }
    });
  }

  actionSheetLikesModal(
    component,
    componentProps: any,
    additionalCssClass = '',
    ondidDismissSuccess?: (_) => void,
    ondidDismissFailure?: (reason: any) => void
  ): Observable<HTMLIonModalElement> {
    return this.xLikeModal
    ('modal-actionSheetLike')
    (
      component,
      componentProps,
      additionalCssClass = '',
      ondidDismissSuccess ? ondidDismissSuccess : undefined,
      ondidDismissFailure ? ondidDismissFailure : undefined
    );
  }

  alertLikeModal(
    component,
    componentProps: any,
    additionalCssClass = '',
    ondidDismissSuccess?: (_) => void,
    ondidDismissFailure?: (reason: any) => void
  ): Observable<HTMLIonModalElement> {
    return this.xLikeModal
    ('modal-alertLike')
    (
      component,
      componentProps,
      additionalCssClass = '',
      ondidDismissSuccess ? ondidDismissSuccess : undefined,
      ondidDismissFailure ? ondidDismissFailure : undefined
    );
  }

  xLikeModal(like: 'modal-actionSheetLike' | 'modal-alertLike') {
    return (
      component,
      componentProps: any,
      additionalCssClass = '',
      ondidDismissSuccess?: (_) => void,
      ondidDismissFailure?: (reason: any) => void
    ) => defer(() => this.modalController.create({
      component,
      componentProps,
      cssClass: `${like}${additionalCssClass ? ` ${additionalCssClass}` : ''}`
    })
    ).pipe(
      tap(modal => {
        if (ondidDismissSuccess !== undefined || ondidDismissFailure !== undefined) {
          modal.onDidDismiss().then(
            ondidDismissSuccess !== undefined ? ondidDismissSuccess : () => { },
            ondidDismissFailure !== undefined ? ondidDismissFailure : () => { }
          );
        }
      })
    );
  }

  presentModale(modale$: Observable<HTMLIonModalElement>) {
    return modale$.subscribe(modal => modal.present());
  }

}
