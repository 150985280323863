import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividePipe } from './divide.pipe';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [DividePipe],
  exports: [DividePipe]
})
export class DivideModule {}
