import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { scan, startWith, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgressionService {
  progressionAbsolute$: Subject<number> = new Subject();
  progressionRelative$: Subject<[number, number]> = new Subject();
  text$: Subject<string> = new Subject();

  constructor() { }

  progression() {
    return this.progressionAbsolute$.pipe(
      switchMap(val => this.progressionRelative$.pipe(
        startWith([0, 0]),
        scan((acc: number, val2) => acc + (val2[0] * val2[1]), val)
      ))
    );
  }

  checkUpdateOver() {
    this.progressionAbsolute$.next(0.1);
    this.text$.next('Récupération des voyages...');
  }

  voyagesHandleOver() {
    this.progressionAbsolute$.next(0.2);
    this.text$.next('Récupération des contenus...');
  }

  contentsHandleOver() {
    this.progressionAbsolute$.next(0.3);
    this.text$.next('Récupération des ressources des voyages...');
  }

  voyagesVouchersHandlerOver() {
    this.progressionAbsolute$.next(0.5);
  }

  voyagesFilesHandleOver() {
    this.progressionAbsolute$.next(0.75);
    this.text$.next('Récupération des ressources des contenus...');
  }

  sychroOver() {
    this.progressionAbsolute$.next(1);
    this.text$.next('Chargement du contenu...');
  }


  ressourceGot(n: number, m: number) {
    this.progressionRelative$.next([1/n, m]);
  }
}
