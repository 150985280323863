/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  appVersion: '1.1.2',
  production: false,
  API_URL: 'https://api-media.odasie.fr/api-mobile/',
  BASE_URL: 'https://api-media.odasie.fr/',
  // API_URL: 'https://bo-private-staging.odasie.fr/symfony/public/api-mobile/',
  // BASE_URL: 'https://bo-private-staging.odasie.fr/symfony/public/',
  IMG_URL: 'contact/${rowid}/cdn?filepath=${pathWithFileName}',
  defaultImage: 'assets/images/image-not-found.jpg',
  devConditions: {
    getImages: false
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoidHV0dHV0IiwiYSI6ImNrbjA0ZWw1bDA5NmIydWxyOXRhejQ0dWQifQ.JrIYACr8qcMzZn0Ud1dwvQ'
  },
  alwaysDisplayButtons: true,
  displayFilesFromWebWhenPlatformWeb: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
