import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherListeModalComponent } from './voucher-liste-modal.component';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [VoucherListeModalComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [VoucherListeModalComponent]
})
export class VoucherListeModalModule { }
