import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notation-modal',
  templateUrl: './notation-modal.component.html',
  styleUrls: ['./notation-modal.component.scss'],
})
export class NotationModalComponent {

  constructor(
    private modalController: ModalController
  ) { }

  googleButtonClick() {
    window.open(`https://www.google.com/search?q=odasie&oq=odasie&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg9MgYIAhBFGD0yBggDEEUYPdIBBzg5NWowajSoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x30502e4231f53b69:0x28a689f7a9a5e7a4,3`, '_blank');
  }

  tripAdvisorButtonClick() {
    window.open(`https://www.tripadvisor.fr/UserReviewEdit-g293916-d16715556-Odasie-Bangkok.html`, '_blank');
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
