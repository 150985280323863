import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrayNotEmptyPipe } from './arrayNotEmpty.pipe';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [ArrayNotEmptyPipe],
  exports: [ArrayNotEmptyPipe]
})
export class ArrayNotEmptyModule {}
