import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {}
  dismiss() {
    this.modalController.dismiss(); // Ferme la modale sans action supplémentaire
  }

  deleteAccount() {
    // Logique de suppression du compte
    // ...
    // Après la suppression réussie, vous pouvez fermer la modale
    this.modalController.dismiss({ deleted: true }); // Passe une valeur indiquant que le compte a été supprimé
  }

}
