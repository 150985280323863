import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Customtour } from 'src/app/models/FromApiModels';

@Component({
  selector: 'app-reservation-modale-alert-popover',
  templateUrl: './reservation-modale-alert-popover.component.html',
  styleUrls: ['./reservation-modale-alert-popover.component.scss'],
})
export class ReservationModaleAlertPopoverComponent {
  @Input() customtour: Customtour;
  @Input() onClick: () => void;
  reserver:boolean=false;
  constructor(
    private popoverController: PopoverController
  ) { }
  ngOnInit() {
    console.log(this.customtour.is_odasie);
    if(this.customtour.is_odasie===1){
      this.reserver=true;
    }else {
      this.reserver=false;
    }
   }
  dismissPopover() {
    this.popoverController.dismiss();
  
  }

}
