import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayNotEmpty'
})
export class ArrayNotEmptyPipe implements PipeTransform {

  transform<T>(arr: T[]): boolean {
    return arr.length > 0;
  }

}
