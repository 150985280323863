import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem, ReadFileResult, WriteFileResult } from '@capacitor/filesystem';
import { defer, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  readFile(path: string, directory?: Directory): Observable<ReadFileResult> {
    return defer(() => Filesystem.readFile({
      path,
      directory
    }));
  }

  getFileUri(path: string, directory: Directory): Observable<string> {
    return defer(() => Filesystem.getUri({
      path,
      directory
    })).pipe(map(getUriResult => Capacitor.convertFileSrc(getUriResult.uri)));
  }

  checkFile(path: string, directory: Directory): Observable<boolean> {
    return defer(() => Filesystem.readFile({
      path,
      directory
    })).pipe(
      catchError(_ => of(false)),
      map(e => e !== false),
      );
    }

  deleteFile(path: string, directory: Directory) {
    return defer(() => Filesystem.deleteFile({
      path,
      directory,
    }));
  }

  createDirectory(path: string, directory: Directory) {
    return defer(() => Filesystem.mkdir({
      path,
      directory,
      recursive: true
    }));
  }

  checkDirectory(path: string, directory: Directory) {
    return defer(() => Filesystem.readdir({
      path,
      directory
    })).pipe(
      catchError(_ => of(false)),
      map(e => e !== false),
    );
  }

  writeDataToLocalDir(data: string, path: string, directory: Directory): Observable<WriteFileResult> {
    return defer(() => Filesystem.writeFile({
      data,
      path,
      directory,
      recursive: true
    }));
  }

  copyFileToLocalDir(oldLocation: string, newLocation: string, toDirectory: Directory ) {
    return defer(() => Filesystem.copy({
      from: oldLocation,
      to: newLocation,
      toDirectory,
    }));
  }

  getFileAs64(path: string, toDirectory: Directory, mipeType = 'data:image/jpg;'): Observable<string> {
    return this.readFile(path, toDirectory).pipe(
      map((fileReadResult: ReadFileResult) => fileReadResult.data),
      map((dataAsString) => `${mipeType}base64,${dataAsString}`)
    );
  }


  getFileAsUri(path: string, directory: Directory) {
    return defer(() => Filesystem.getUri({
      path,
      directory
    }));
  }

}
