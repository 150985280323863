import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'divide'
})
export class DividePipe implements PipeTransform {

  transform(divided, divider): any {
    return Number(divided) / (Number(divider) || 1);
  }

}
