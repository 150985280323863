/* eslint-disable max-len */
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';
import { Customtour } from 'src/app/models/FromApiModels';
import { Presale, presale, TravelDiaryRead, travel_diary_read, Validate, validate } from 'src/app/models/Models';

const formatedDateRegex = /(\d{2})\/(\d{2})\/(\d{4})/;

export const isFormatedDate = (formatedDate: string) => formatedDateRegex.test(formatedDate);

export const formatDateIntoDate = (formatedDate: string) => formatedDate.replace(formatedDateRegex, (_, p1, p2, p3) => `${p3}/${p2}/${p1}`);

/* eslint-disable @typescript-eslint/dot-notation */
const getFileReader: () => FileReader = () => {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)[`__zone_symbol__originalInstance`];
    return zoneOriginalInstance || fileReader;
};

export const blobTo64: (blob: Blob) => Promise<string> = (blob: Blob) => new Promise(resolve => {
    const reader = getFileReader();
    reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
    };
    reader.readAsDataURL(blob);
});


export const filterVoyage = (voyage: Customtour, state: 'presale' | 'validated') => voyage !== null && voyage !== undefined && (state === 'presale'
    ? presale.indexOf(voyage.status.id as Presale) !== -1
    : [
        ...validate,
        ...travel_diary_read,
    ].indexOf(voyage.status.id as Validate | TravelDiaryRead) !== -1);

export const hasDiary = (voyage: Customtour) => travel_diary_read.indexOf(voyage.status.id as TravelDiaryRead) !== -1;

export const beforeDate = (comparedDate: Date, date: Date) => comparedDate <= date;

export const strictlyBeforeDate = (comparedDate: Date, date: Date) => comparedDate < date;

export const betweenDates = (comparedDate: Date, date1: Date, date2: Date) => beforeDate(date1, comparedDate) && beforeDate(comparedDate, date2);

export const securityForkJoin: <T>(obs: Observable<T>[]) => Observable<T[]> = <T>(obs: Observable<T>[]) => forkJoin(obs).pipe(defaultIfEmpty([]));

export const compareCheckinAndDayDate = (checkin: string) => (dayDate: string) => {
    const a = new Date(formatDateIntoDate(checkin));
    const b = new Date(dayDate);
    return a.getTime() === b.getTime();
};

const degreesToRadians = (degrees) => degrees * Math.PI / 180;

export const distanceInKmBetweenEarthCoordinates = (lat1, lon1) => (lat2, lon2) => {
    const earthRadiusKm = 6371;

    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);

    const latDtL1 = degreesToRadians(lat1);
    const latDtL2 = degreesToRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(latDtL1) * Math.cos(latDtL2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
};

export const firstTransform = (ressource: string) => ressource.charAt(0) === '/' ? ressource.slice(1) : ressource;

export const lastTransform = (ressource: string) => ressource.replace(/^\.\.\/\.\./, '');

export const completeTransform = (src: string) => lastTransform(firstTransform(src));

export const addZeroIfNecessary = (num: number) => num < 10 ? `0${num}` : `${num}`;

export const datePureFromDate = (date: Date) => new Date(`${date.getFullYear()}-${addZeroIfNecessary(date.getMonth() + 1)}-${addZeroIfNecessary(date.getDate())}`);
