/* eslint-disable @typescript-eslint/naming-convention */

import { Content, Customtour, PointOfInterest, Voucher } from './FromApiModels';

// TODO-pf spécifier inconnus

export const presale = ['1', '20', '21'] as const;
export const validate = ['30', '31', '40', '41', '42', '200', '50'] as const;
export const travel_diary_read = ['51', '52'] as const;
export const other = ['100', '401', '404'] as const;
export const allIds = [...presale, ...validate, ...travel_diary_read, ...other] as const;

export type Presale = typeof presale[number];
export type Validate = typeof validate[number];
export type TravelDiaryRead = typeof travel_diary_read[number];
export type Other = typeof other[number];
export type AllIds = typeof allIds[number];

export interface SortedValidatedVoyages {
    ongoing: Customtour[];
    future: Customtour[];
    past: Customtour[];
}

export interface UserInfos {
    id: string;
    tiers_id: string;
    email: string;
    fullname: string;
    lastname: string;
    firstname: string;
    civility: string;
    address: string;
    zip_code: string;
    city: string;
    country: string;
    phone: string;
    birth_date: ApiDate;
    passport: Passport;
}

export interface Passport {
    passport_number: string;
    city: string;
    country: string;
    delivery_date: ApiDate;
    expiration_date: ApiDate;

}

export interface ApiDate {
    full: string;
    day: string;
    month: string;
    year: string;

}

export interface LoginReturnValue {
    token: string;
    client: UserInfos;
}

export interface ValueMessage {
    field: string;
    message: string;
    value: string;
}

export interface EditUserReturnValue {
    client: UserInfos;
    errors: ValueMessage[];
    new_token: null | string;
}

export interface TitleOrContent {
    title?: string;
    content?: string;
}

export interface UpdatedElem {
    updated_at: string;
}

export interface UpdatedVoyage extends UpdatedElem {
    id: string;
}

export interface UpdatedContent extends UpdatedElem {
    id: string;
}

export interface FullUri {
    uriTail: string;
    uriHead?: string;
}

export interface SynchroReturnValue {
    client: UserInfos;
    contents: {
        generic_contents: UpdatedContent[];
        news: UpdatedContent[];
        odasie_contact: UpdatedContent[];
    };
    customtours: UpdatedVoyage[];
    app_version: string;
}

export interface CustomtourReturnValue {
    resources: {
        name: string;
        path: string;
    }[];
    customtours: Customtour[];
    points_of_interest: PointOfInterest[];
}

export interface ContentsReturnValue {
    resources: {
        name: string;
        path: string;
    }[];
    contents: {
        generic_contents: Content[];
        news: Content[];
    };
}

export interface CdnResult {
    success: boolean;
    filePath: string;
}

export interface VoucherCdnResult {
    success: boolean;
    voucher: Voucher;
}

export interface PointInteretCategorie {
    id: string;
    label: string;
    group_id: string;
    sous_group_id: null | string;
}
