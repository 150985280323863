import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationModaleAlertPopoverComponent } from './reservation-modale-alert-popover.component';
import { SumModule } from 'src/app/pipes/sum/sum-module';
import { DivideModule } from 'src/app/pipes/divide/divide-module';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [ReservationModaleAlertPopoverComponent],
  imports: [
    CommonModule,
    IonicModule,
    SumModule,
    DivideModule,
  ],
  exports: [ReservationModaleAlertPopoverComponent],
})
export class ReservationModaleAlertPopoverModule { }
