/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationBehaviorOptions, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Directory } from '@capacitor/filesystem';
import { ModalController, PopoverController } from '@ionic/angular';
import { BehaviorSubject, forkJoin, from, Observable, Subject } from 'rxjs';
import { catchError, concatMap, map, share } from 'rxjs/operators';
import { HebergementModalComponent } from 'src/app/components/hebergement-modal/hebergement-modal.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { ReservationModalComponent } from 'src/app/components/reservation-modal/reservation-modal.component';
import { ReservationModaleAlertPopoverComponent } from 'src/app/components/reservation-modale-alert-popover/reservation-modale-alert-popover.component';
import { Customtour, Day, Point, PointOfInterestCategory, SupplierVoucherFile, Voucher } from 'src/app/models/FromApiModels';
import { betweenDates, blobTo64, filterVoyage, formatDateIntoDate, isFormatedDate } from 'src/app/utils/functions';
import { environment } from 'src/environments/environment';
import { VoucherListeModalComponent } from '../components/voucher-liste-modal/voucher-liste-modal.component';
import { DataService } from './data.service';
import { FileService } from './file.service';
import { SynchroService } from './synchro.service';
import { NotationModalComponent } from '../components/notation-modal/notation-modal.component';

@Injectable({
  providedIn: 'root'
})
export class TripService {
  trip$: Observable<Customtour>;
  tripType$: Observable<'validated' | 'presale'>;
  vouchers$: Observable<Voucher[]>;
  tripPointsOfInterest$: Observable<Point[]>;
  tripCategoriesPointsOfInterest$: Observable<PointOfInterestCategory[]>;
  tripCategoriesPointsOfInterestNonActivites$: Observable<PointOfInterestCategory[]>;
  tripCategoriesPointsOfInterestActivites$: Observable<PointOfInterestCategory[]>;
  dateclick$: Subject<string> = new Subject();

  voucherLinkArray$: BehaviorSubject<{ titre: string; link: string }[]> = new BehaviorSubject([]);

  /** ids réservés aux catégories de points d'intéret de type "activité" */
  categoryFilterActivity: string[] = ['10', '11', '12', '13', '14', '15', '16', '17', '18', '20', '22'];

  constructor(
    private dataService: DataService,
    private fileService: FileService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private router: Router,
    private sanitizer: DomSanitizer,
    private synchroService: SynchroService,
  ) {
    this.instantiateValues();
  }

  instantiateValues() {
    const trip$ = this.dataService.getVoyages().pipe(
      // TODO-pf retirer cette ligne - fait
      map(voyages => voyages.find(voyage => voyage.id === this.router.url.split('/')[3])),
      share(),
    );

    this.trip$ = trip$;

    this.tripType$ = trip$.pipe(
      map(trip => filterVoyage(trip, 'validated') ? 'validated' : 'presale'),
    );
    this.vouchers$ = trip$.pipe(
      // eslint-disable-next-line @typescript-eslint/dot-notation
      map((trip) => Array.isArray(trip['vouchers']) ? trip['vouchers'] : [])
    );

    const tripPointOfInterest$ = forkJoin([
      this.trip$,
      this.dataService.getPointsOfInterest()
    ]).pipe(
      map(([customtour, pointsOfInterest]) => ({
        customtour,
        pointsOfInterest: pointsOfInterest.find(poi => poi.customtour_id === Number(customtour.id))
      })),
      map(({ customtour, pointsOfInterest }) => {
        const within = 30;
        return pointsOfInterest;
        /** pas de filtration pour le moment */
        // .filter(point => customtour.destinations.some(dest =>
        //   distanceInKmBetweenEarthCoordinates(Number(point.latitude), Number(point.longitude))(Number(dest.latitude), Number(dest.longitude)) <= within
        //   || true
        // ));
      }),
      share()
    );

    this.tripPointsOfInterest$ = tripPointOfInterest$.pipe(
      map(pointOfInterest => pointOfInterest.points)
    );

    this.tripCategoriesPointsOfInterest$ = tripPointOfInterest$.pipe(
      map(pointOfInterest => pointOfInterest.categories)
    );

    this.tripCategoriesPointsOfInterestNonActivites$ = this.tripCategoriesPointsOfInterest$.pipe(
      map(pointOfInterestCategories => pointOfInterestCategories.filter(pointOfInterestCategory => pointOfInterestCategory.id !== '9' && !this.categoryFilterActivity.includes(pointOfInterestCategory.id))),
    );

    this.tripCategoriesPointsOfInterestActivites$ = this.tripCategoriesPointsOfInterest$.pipe(
      map(pointOfInterestCategories => pointOfInterestCategories.filter(pointOfInterestCategory => this.categoryFilterActivity.includes(pointOfInterestCategory.id))),
    );

  }

  voucherClick(voucher: Voucher) {
    const fromWeb$ = this.synchroService.fetchVoucherJpg(voucher.id).pipe(concatMap(blob => from(blobTo64(blob))));
    const fromDeviceDatas$ = this.fileService.getFileAs64(`vouchers/voucher_${voucher.id}.jpeg`, Directory.Data).pipe(catchError(err => fromWeb$));
    const voucherAsBase64 = Capacitor.getPlatform() === 'web' && environment.displayFilesFromWebWhenPlatformWeb
    ? fromWeb$
    : fromDeviceDatas$;

    voucherAsBase64.toPromise()
    .then(base64 => ({ safeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(base64), base64 }))
    .then((state: { safeUrl: SafeResourceUrl; base64: string }) => ({ state }))
    .then((extra: NavigationBehaviorOptions) => this.router.navigateByUrl('/home/pdf-view', extra)
    );

  }

  hebergementClick(day: Day) {
    this.trip$.pipe(
      map(trip => day.customtour_hotels.map(customtourHotel => trip.hotels.find(hotel => hotel.id === customtourHotel.hotel.id)))
    ).subscribe(hotels => {
      this.modalController.create({
        component: HebergementModalComponent,
        componentProps: {
          day,
          hotels
        },
      }).then(modal => modal.present());
    });
  }

  displayAlertPopover($event) {
    this.popoverController.create({
      component: PopoverComponent,
      componentProps: { message: 'Vous devez impérativement nous transmettre les informations relatives à vos vols.' },
      cssClass: 'document_status_popover',
      event: $event,
      side: 'start',
      showBackdrop: false
    }).then(popover => popover.present());
  }

  displayReservationModaleAlertPopover($event, customtour: Customtour) {
    this.popoverController.create({
      component: ReservationModaleAlertPopoverComponent,

      componentProps: {
        customtour,
        onClick: () => this.openReservationModale(customtour)
      },
      event: $event,
      side: 'start',
      showBackdrop: false,
    }).then(popover => popover.present());
  }

  openReservationModale(customtour: Customtour) {
    this.modalController.create({
      component: ReservationModalComponent,
      cssClass: 'modal-fullScreen',
      componentProps: { customtour },
    }).then(modal => modal.present());
  }

  isValidatedAndWithinDateRange(
    isValidated: boolean,
    start: string,
    end: string,
    finalNow: Date,
    howManyDaysBefore: number,
    howManyDaysAfter: number
  ) {
    if (isValidated && isFormatedDate(start) && isFormatedDate(end)) {
      const startCanReserve = new Date(formatDateIntoDate(start));
      const endCanReserve = new Date(formatDateIntoDate(end));
      startCanReserve.setDate(startCanReserve.getDate() - howManyDaysBefore);
      endCanReserve.setDate(endCanReserve.getDate() + howManyDaysAfter);
      return betweenDates(finalNow, startCanReserve, endCanReserve);
    } else {
      return false;
    }
  }

  voirLalisteDesVouchers(data: SupplierVoucherFile[]) {
    this.modalController.create({
      component: VoucherListeModalComponent,
      componentProps: { data }
    }).then(modal => modal.present());
  }

  displayNotationModale() {
    this.modalController.create({
      component: NotationModalComponent,
      componentProps: { },
      cssClass: ['modal-note'],
    }).then(modal => modal.present());
  }

}
